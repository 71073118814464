const resources = {
  en: {
    translation: {
      name:"Name",
      country:"country",
      state:"State",
      city:"city",
      mobileNo:"Mobile No",
      continueToCheckOut:"continue To Check out",
      direction:"ltr",
      Supermarket:"Supermarket",
      Fashion:"Fashion",
      "Phones & Tablets":"Phones & Tablets",
      "Home & Office":"Home & Office",
      Electronics:"Electronics",
      login:"login",
      myProfile:"My Profile",
      createAccount:"Create Account",
      createFegusplaceAccount:"Create your Fegusplace account",
      password:"password",
      loginparagraphe:"Create your Fegusplace customer account in just a few clicks! You can register either using your e-mail address or through your Facebook account.",
      registerWithNewAccount:"Register new account",
      rememberMe:"Remember Me",
      loginWithFacebook:"login with facebook",
      registerWithFacebook:"register with facebook",
      currency:"NGN",
      editInfo:"Edit Information",
    ContactInfo:"Contact Info",
    checkOut:"Checkout",
      ContinueToShopping:"Continue to Shopping",
      ContinueToCheckOut:"Continue to CheckOut",
      SubTotal:"SubTotal",
      quantity:"quantity",
      price:"price",
      total:"Total",
      remove:"Remove",
      items:"Items",
      item:"Item",
      cart:"Cart",
      app_name: "Fegusplace",
      help: "help",
      lang: "language",
      top_search: "Search for products",
      offical: "Official stores",
      address:"Address",
      address2:"Address 2 option",
      fegusplaceDoniation: "Fegusplace Doniation",
      orangePoints: "Orange Points",
      emailAddress:"Email Address",
      superMarket: "Supermarket products",
      fashion: "Fashions products",
      PhonesTablets: "Phones & Tablets products",
      homeOffice: "Home & Office products",
      electroic: "Electronics products",

      newToJuima: "NEW TO FEGUSPLACE?",
      subcsribeMesg:
        "Subscribe to our newsletter to get updates on our latest offers!",
      downloadJumi: "DOWNLOAD FEGUSPLACE FREE APP",
      getAccess: "Get access to exclusive offers!",
      letUsHelpU: "LET US HELP YOU",
      helpCenter: "Help center",
      contactUs: "Contact Us",
      privacy: "Privacy",
      shipDP: "Shipping & Delivery Policy",
      termsAndCondtion: "Terms and Conditions",
      reportAPro: "Report a product",
      joinUs: "JOIN US ON",
      aboutFegusplaceEgypt: "ABOUT FEGUSPLACE",
      howToPy: "How to pay on Fegusplace?",
      returnPolicy: "Return Policy",
      makeMoeny: "MAKE MONEY WITH FEGUSPLACE",
      sellOnFegusplace: "Sell on Fegusplace",
      fegusplaceInterNational: "FEGUSPLACE INTERNATIONAL",
      Algeria: "Algeria",
      Ghana: "Ghana",
      Kenya: "Kenya",
      Morocco: "Morocco",
      shippingInfo:"Shipping Information",
      CustomService: "Custom Service",
      Delivery: { title: "Free Delivery", body: "From NGN 1000" },
      Support: { title: "Support 24/7", body: "Online 24 hours" },
      return: { title: "Free Return", body: "365 a day" },

      shipping: {
        title: "Your order of NGN 1000 or more gets free standard delivery.",
        details: "Standard delivered 4-5 Business Days",
        details1: "Express delivered 2-4 Business Days",       
        body:
          "Orders are processed and delivered Monday-Friday (excluding public holidays)",
        footer:
          "No Returns/Replacements Allowed - Returns or replacements are not accepted by seller for this product. Cancellation allowed.",
        },
loginCredential:"Please You need Login To Continue to checkout ",

LoginToContinue:"Login To Continue",
emptyCart:"your cart is empty",
    },
  },
  ar: {
    translation: {
      emptyCart:"السلة فارغه",
      LoginToContinue:"سجل للمتابعه",
      loginCredential:"من فضلك قم بتسجيل الدخول لتتمكن من المتابعه",
      currency:"جنيه",
      registerWithFacebook:"تسجيل الدخول عبر الفيسبوك",
      loginWithFacebook:"تسجيل الدخول عبر الفيسبوك ",
      rememberMe:"تذكرني",
      registerWithNewAccount:"سجل بحساب جديد",
      loginparagraphe:`قم بإنشاء حسابك الخاص علي جوميا بكل سهولة عن طريق الايميل او عن طريق الفيسبوك`,
      password:"كلمة السر",
      myProfile:" الحساب",
      createAccount:"إنشاء حساب ",
      createFegusplaceAccount:"إنشاء حساب جوميا",
      login:"تسحيل الدخول",
      Supermarket:"سوبر ماركت",
      Fashion:"الموضه",
      "Phones & Tablets":"موبايلات وتابلت",
      "Home & Office":"المنزل و المكتب",
      Electronics:"إلكترونيات",
      continueToCheckOut:"اكمل عملية الدفع",
      mobileNo:"رقم الهاتف",
      country:"الدوله",
      state:"المحافظه",
      city:"المدينه",
      name:"الاسم",
      shippingInfo:"تفاصيل الشحن",
      address:"العنوان",
      address2:"العنوان  اختياري",
      emailAddress:"البريد الالكتروني",
      editInfo:"تعديل المعلومات",
      ContactInfo:"معلومات الاتصال",
      checkOut:"انهاء الدفع",
      ContinueToShopping:"اكمل التسوق",
      ContinueToCheckOut:"الذهاب للدفع",
      SubTotal:"اجمالي سعر المنتح",
      quantity:"الكميه",
      price:"السعر",
      total:"اجمالي السعر",
      remove:"احذف",
      items:"منتجات",
      item:"منتج",
      cart:"سلة المشتريات",
      app_name: "جوميا",
      help: "المساعدة",
      lang: "اللغة",
      top_search: "البحث عن المنتجات",
      offical: "المتاجر الرسمية",
      fegusplaceDoniation: "التبرعات",
      orangePoints: "نقاط Orange",
      direction:"rtl",
      superMarket: "منتجات السوبرماركت",
      fashion: "منتجات الموضة",
      PhonesTablets: "منتجات الموبايل والتابلت",
      homeOffice: "منتجات المكاتب",
      electroic: "المنتجات الإلكترونية",

      newToJuima: "مستخدم جديد لدي جوميا؟",
      subcsribeMesg:
        "إشترك في نشرتنا البريدبة وإحصل علي آخر العروض والتحديثات.",
      downloadJumi: "قم بتحميل التطبيق",
      getAccess: "إحصل علي العروض الحصرية",
      letUsHelpU: "دعنا نساعدك",
      helpCenter: "مركز المساعدة",
      contactUs: "إتصل بنا",
      privacy: "سياسة الخصوصية",
      shipDP: "سياسة التوصيل",
      termsAndCondtion: "إتفاقية الإستخدام",
      reportAPro: "الإبلاغ عن مشكلة",
      joinUs: "إنضم إلينا",
      aboutFegusplaceEgypt: "عن جوميا مصر",
      howToPy: "كيفية الدفع علي جوميا",
      returnPolicy: "سياسة الإسترجاع",
      makeMoeny: "العمل مع جوميا بالعمولة",
      sellOnFegusplace: "البيع علي جوميا",
      fegusplaceInterNational: "جوميا الدولي",
      Algeria: "الجزائر",
      Ghana: "غانا",
      Kenya: "كينيا",
      Morocco: "المغرب",

      CustomService: "خدمات المستخدم",
      Delivery: { title: "شحن مجاني", body: "ابتداء من 900 جنية مصري" },
      Support: { title: "الدعم 24/7", body: "متوفر 24 ساعه" },
      return: { title: "استعاده مجانية", body: "365 يوم " },

      shipping: {
        title: "طلبك بقيمة 100 دولار أو أكثر يحصل على توصيل قياسي مجاني.",
        details: "تسليم قياسي 4-5 أيام عمل",
        details1: "التسليم السريع 2-4 أيام عمل",
        body:
          "تتم معالجة الطلبات وتسليمها من الاثنين إلى الجمعة (باستثناء أيام العطل الرسمية)",
        footer:
          "لا يسمح بالإرجاع / الاستبدال - لا يقبل البائع المرتجعات أو الاستبدال لهذا المنتج. الإلغاء مسموح به.",
      },
    },
  },
};

export default resources;
